<template>
  <b-modal
    ref="editOppurtunityDocumentModal"
    no-close-on-esc
    no-close-on-backdrop
    :title="$t('opportunities.editOpportunity.document.edit.header')"
    @show="showModal"
    @close="backToList"
  >
    <cp-input-container
      v-if="currentOpportunity"
      ref="cpInputContainer"
      v-model="model"
      @edited="editHandler"
    >
      <div class="row">
        <div class="col-sm-12">
          <template v-if="!showUploader">
            <cp-button
              variant="outline-dark"
              @click="editImage=true"
            >
              {{ $t('opportunities.editOpportunity.document.edit.changeFile.label') }}
            </cp-button>
          </template>
          <template v-if="showUploader">
            <cp-file-uploader
              id="opportunity-document-uploader"
              :data-check="checkDockSize"
              :file-rules="checkDockSize"
              :document-error="documentError"
              :max-file-size="maxDocSize"
              class-style-override="small-footprint"
              show-default-message
              @onChange="onDocumentUploaded"
              @fileLoading="fileLoading"
            />
          </template>
        </div>

        <div class="col-sm-12">
          <cp-input
            v-model="model.title"
            :class="'mt-3'"
            name="title"
            :label="$t('opportunities.editOpportunity.document.edit.title.label')"
            validate="required"
          />
        </div>
        <div class="col-sm-12">
          <h5> {{ $t('opportunities.editOpportunity.document.edit.restrictions.label') }} </h5>
        </div>
        <div class="col-md-6">
          <label
            class="switcher mt-2"
            for="requireKyc"
          >
            <span class="ml-3">{{ $t('opportunities.editOpportunity.document.edit.restrictions.kycOrKyb') }}</span>
            <input
              id="requireKyc"
              v-model="model.requireKyc"
              type="checkbox"
              class="switcher-input"
            >
            <span class="switcher-indicator">
              <span class="switcher-yes" />
              <span class="switcher-no" />
            </span>
          </label>
        </div>
        <div class="col-md-6">
          <label
            class="switcher mt-2"
            for="requireQualification"
          >
            <span class="ml-3">{{ $t('opportunities.editOpportunity.document.edit.restrictions.qualification') }}</span>
            <input
              id="requireQualification"
              v-model="model.requireQualification"
              type="checkbox"
              class="switcher-input"
            >
            <span class="switcher-indicator">
              <span class="switcher-yes" />
              <span class="switcher-no" />
            </span>
          </label>
        </div>
        <div class="col-sm-12">
          <cp-input
            key="order"
            v-model="model.order"
            input-type="number"
            :label="$t('opportunities.editOpportunity.document.edit.order.label')"
            name="order"
            validate="required"
          />
        </div>
      </div>
    </cp-input-container>
    <b-alert
      v-if="hasError"
      variant="danger"
      show
    >
      {{ serverError }}
    </b-alert>

    <template #modal-footer>
      <cp-button
        :disabled="createProcess"
        size="md"

        variant="primary"
        :is-loading="saving"
        @click="saveChanges"
      >
        {{ ((currentDocument) ? $t('opportunities.editOpportunity.document.edit.saveButton.label'): $t('opportunities.editOpportunity.document.edit.addButton.label')) }}
      </cp-button>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import cpInputContainer from '~/components/common/cpInputContainer';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'UpdateOpportunityDocumentModal',
  components: {
    CpButton,
    CpInput,
    cpInputContainer,
    CpFileUploader,
  },
  metaInfo: {
    title: 'Add / Edit Opportunity Document',
  },
  data() {
    return {
      hasError: false,
      createProcess: false,
      serverError: '',
      saving: false,
      maxDocSize: 50,
      model: {},
      documentError: false,
      editImage: false,
    };
  },
  computed: {
    ...mapGetters('opportunities', ['currentOpportunity']),
    currentDocument() {
      if (!this.currentOpportunity || !this.currentOpportunity.documents) return null;
      if (!this.$route.params.docId) return null;
      const doc = (this.currentOpportunity.documents).filter(({ id }) => id === this.$route.params.docId * 1);
      return (doc && doc.length) ? doc[0] : null;
    },
    showUploader() {
      return !this.currentDocument || this.editImage;
    },
  },
  mounted() {
    const doc = this.currentDocument;
    this.model = (doc) ? { ...doc } : { order: 0 };
    this.showModal();
  },
  created() {


  },
  methods: {
    ...mapActions('opportunities', ['updateOpportunityDocument', 'getOpportunityById', 'addOpportunityDocument']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    saveChanges(env) {
      this.hasError = false;
      env.preventDefault();
      if (!this.model.id && !this.model.tmpImageFileKey) {
        this.documentError = true;
      }
      this.$refs.cpInputContainer.validateForm()
        .then(async () => {
          if (this.$refs.cpInputContainer.isValid()) {
            if (this.documentError) return;
            this.saving = true;
            try {
              const { idIssuer: issuerId } = this.$route.params;
              const opportunityId = this.currentOpportunity.id;
              const opportunityDocument = { ...this.model, requireKyc: !!this.model.requireKyc, requireQualification: !!this.model.requireQualification };
              if (this.currentDocument) {
                await this.updateOpportunityDocument({ issuerId, opportunityId, opportunityDocument });
              } else {
                await this.addOpportunityDocument({ issuerId, opportunityId, opportunityDocument });
              }
              await this.getOpportunityById({ issuerId, opportunityId, additionalFields: 'attributes,documents,contents,languages' });
              this.backToList();
            } catch (x) {
              if (x && x.response && x.response.data && x.response.data.message) {
                this.hasError = true;
                this.serverError = x.response.data.message;
              }
            }
            this.saving = false;
          }
        });
    },
    showModal() {
      this.$refs.editOppurtunityDocumentModal.show();
    },
    backToList(env) {
      if (env) env.preventDefault();
      this.$router.push({ name: 'OpportunityDocumentsMain' });
    },
    editHandler() {
      this.hasError = false;
    },
    checkDockSize({ size }) {
      return size > 10 * 10000000; // If more than 10 MB, reject
    },
    onDocumentUploaded({ file, fileKey }) {
      if (!fileKey) {
        this.onUploadError(file);
        this.model.tmpImageFileKey = '';
      } else {
        this.documentError = false;
        this.model.tmpImageFileKey = fileKey;
        this.model.fileType = file.type;
        this.createProcess = false;
      }
    },
    onUploadError(file) {
      let errorText = 'Upload Error';
      if (file && file.status === 'error') {
        if (file.xhr && file.xhr.responseText) {
          errorText = file.xhr.responseText;
        }
      }
      this.showErrorMessage(errorText);
    },
    showErrorMessage(error) {
      this.CALL_ERROR_TOASTER(error);
      this.$log.error('Opportunity add document upload file error:', error);
    },
    fileLoading() {
      this.createProcess = true;
    },
  },

};
</script>
